import React from "react"
import { Box, Grid, Text, Container, Heading, Link } from "theme-ui"
import { GatsbyImage } from "jam-cms"

// import app components
import BackgroundImage from "../BackgroundImage"
import Edges from "../Edges"
import Logo from "../../icons/Union.svg"
import LargLogo from "../../icons/pwww_logo_for-centre_neg.svg"
import DownArrow from "../../icons/down-arrow.svg"

const Hero = (props) => {
  const { headlines, image, mobileimage } = props

  return (
    <Container
      sx={{
        html: {
          scrollBehavior: "smooth",
        },
      }}
    >
      <Grid
        sx={{
          postion: "relative",
          zIndex: 3,
          gridRow: 1,
          height: "85%",
          minHeight: `500px`,
          pt: "0",
          gap: 0,
        }}
        columns={[1, "20% 43% 37%", "20% 43% 37%"]}
      >
        <Box
          sx={{
            gridColumnStart: [2, 1],
            gridRowStart: [2, 1],
            display: "flex",
            justifyContent: "flex-end",
            svg: { zIndex: 5, mt: "auto" },
            mb: "-1px",
          }}
        >
          <Logo />
        </Box>

        <Box
          sx={{
            gridColumnStart: [1, 2],
            gridColumnEnd: 3,
            gridRowStart: 1,
            gridRowEnd: [3, 1],
            "@media (min-width: 1024px)": {
              mt: "80px",
              borderLeft: [null, "1px solid white"],
            },
            bg: "rgba(3, 15, 26, 0.7)",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            heigh: "200px",
            zIndex: 10,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              svg: {
                "@media (max-width:1130px)": {
                  width: "250px",
                  height: ["100px", "110px"],
                },
              },
              pb: "30px",
            }}
          >
            <LargLogo />
          </Box>{" "}
          {headlines &&
            headlines.length > 0 &&
            headlines.map((o, i) => (
              <Heading
                key={i}
                color="white"
                sx={{
                  textAlign: ["start", "center"],
                  px: [null, "10px"],
                }}
                variant="styles.heroHeading"
                children={o.headline}
              />
            ))}
          <Link
            href="#single"
            sx={{
              position: "absolute",
              bottom: "10px",
              left: 0,
              right: 0,
              textAlign: "center",
              justifySelf: "end",
              pt: "30px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <DownArrow />
          </Link>
        </Box>

        <Box
          as="div"
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: [3, -1],
            gridRowStart: 1,
            gridRowEnd: [3, -1],
            position: "relative",
            width: "100%",
            height: "85vh",
            minHeight: `500px`,

            ".gatsby-image-wrapper": {
              width: "100%",
              height: "85vh",
              minHeight: `500px`,
            },
            "&:before": {
              content: "''",
              position: "absolute",
              left: "0",
              bottom: 0,
              width: "100%",
              height: "85vh",
              background:
                "linear-gradient(29.14deg, #FFFFFF -59.66%, rgba(255, 255, 255, 0) 42.34%)",
              zIndex: 4,
              minHeight: `500px`,
            },
          }}
        >
          {image && <GatsbyImage image={image} />}
        </Box>
      </Grid>
    </Container>
  )
}

export default Hero
