import React from "react"
import {
  Box,
  Grid,
  Text,
  Container,
  Heading,
  Embed,
  Link as ThemeLink,
} from "theme-ui"
import Parser from "html-react-parser"
import { GatsbyImage, RichText } from "jam-cms"

// import app components
import BackgroundImage from "../BackgroundImage"
import Edges from "../Edges"
import Textarea from "../Textarea"

const Contact = (props) => {
  const {
    headline,
    image,
    text,
    emailaddress,
    address,
    phonenumber,
    fax,
    link,
  } = props

  return (
    <Container>
      <Box
        sx={{
          display: "grid",
          "@media (max-width: 900px)": {
            gridTemplateColumns: "1fr",
            gridTemplateRows: "1fr 1fr 1fr",
          },
          gridTemplateColumns: "1fr 4fr 9fr 2fr",
          gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",

          postion: "relative",
          zIndex: 9,
          gap: 0,
          background:
            "linear-gradient(36.17deg, #FFFFFF -15.68%, rgba(255, 255, 255, 0) 52.05%)",
        }}
      >
        <Box
          sx={{
            "@media (max-width:900px)": {
              gridRowStart: 1,
              gridRowEnd: 2,
              gridColumnStart: 1,
              gridColumnEnd: 2,
            },
            gridRowStart: 1,
            gridRowEnd: 5,
            gridColumnStart: 2,
            gridColumnEnd: 3,
            "@media (min-width: 1024px)": {
              mt: "80px",
            },
            bg: "rgba(3, 15, 26, 0.7)",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            zIndex: 10,
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
            p: ["20px", "30px", "30px"],
            "@media (min-width: 1100px)": {
              p: "60px",
            },
          }}
        >
          {text && (
            <Heading variant="text.sectionHeading" mt={["150px", "auto"]}>
              {Parser(text)}
            </Heading>
          )}
        </Box>
        <Box
          sx={{
            "@media (max-width:900px)": {
              gridRowStart: 2,
              gridRowEnd: 3,
              gridColumnStart: 1,
              gridColumnEnd: 2,
              bg: "white",
            },
            gridRowStart: 5,
            gridRowEnd: 9,
            gridColumnStart: 2,
            gridColumnEnd: 3,
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 10,
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            flexDirection: "column",
            pt: "30px",
            pr: ["20px", "30px", "20px"],
            pl: ["20px", "30px", "30px"],
            pb: ["30px", null],
            "@media (min-width: 1100px)": {
              pr: "70px",
              pt: "50px",
              pl: "60px",
            },
          }}
        >
          {headline && (
            <Box>
              <Heading
                variant="text.contactHeading"
                children={Parser(headline)}
              />
            </Box>
          )}

          {address && (
            <Box
              sx={{
                pt: ["10px", "30px", "30px"],
                pr: "30px",
              }}
            >
              <Textarea content={address} />
            </Box>
          )}

          {phonenumber && (
            <Box
              variant="text.footerParagraph"
              sx={{
                pt: ["10px", "10px", "30px"],
              }}
            >
              <Text sx={{ display: "inline-block", color: "bgDark", pr: 2 }}>
                {`T :`}
              </Text>

              <ThemeLink
                sx={{
                  textDecoration: "none",
                  color: "bgDark",
                  "&:hover": { color: "bgGreen" },
                }}
                href={`tel:${phonenumber}`}
                variant="hyperlink"
              >
                {phonenumber}
              </ThemeLink>
            </Box>
          )}
          {fax && (
            <Box variant="text.footerParagraph" sx={{}}>
              <Text sx={{ display: "inline-block", color: "bgDark", pr: 2 }}>
                {`F :`}
              </Text>

              <ThemeLink
                sx={{
                  textDecoration: "none",
                  color: "bgDark",
                  "&:hover": { color: "bgGreen" },
                }}
                href={`tel:${fax}`}
                variant="hyperlink"
              >
                {fax}
              </ThemeLink>
            </Box>
          )}
          {emailaddress && (
            <Box
              variant="text.footerParagraph"
              sx={{
                pb: ["10px", "20px"],
                pt: ["10px", "10px", "30px"],
              }}
            >
              <ThemeLink
                sx={{
                  textDecoration: "none",
                  color: "bgDark",
                  "&:hover": { color: "bgGreen" },
                }}
                href={`mailto:${emailaddress}`}
                variant="emaillink"
              >
                {emailaddress}
              </ThemeLink>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            "@media (max-width:900px)": {
              gridRowStart: 3,
              gridRowEnd: 4,
              gridColumnStart: 1,
              gridColumnEnd: 2,
            },
            gridRowStart: 4,
            gridRowEnd: 8,
            gridColumnStart: 3,
            gridColumnEnd: 4,

            zIndex: 10,
            position: "relative",
          }}
        >
          {link && (
            <Embed
              sx={{
                height: "100%",
              }}
              src={Parser(link.url)}
            />
          )}
        </Box>
        <Box
          as="div"
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: -1,
            gridRowStart: 1,
            gridRowEnd: -1,
            position: "relative",
            width: "100%",
            height: "100%",
            ".gatsby-image-wrapper": {
              width: "100%",
              height: "100%",
              "@media (max-width:900px)": {
                height: ["400px", "600px"],
              },
            },
          }}
        >
          {image && <GatsbyImage image={image} />}
        </Box>
      </Box>
    </Container>
  )
}

export default Contact
