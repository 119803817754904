import React from "react"
import { Box, Grid, Text, Container } from "theme-ui"
import Parser from "html-react-parser"

// import app components
import BackgroundImage from "../BackgroundImage"
import Edges from "../Edges"
import Button from "../Button"

const LogoText = (props) => {
  const { image, alignment, headline, text, link } = props

  return (
    <Container sx={{ heigh: "100vh" }}>
      <Grid
        sx={{
          postion: "relative",
          zIndex: 9,
          bg: "pink",
          gridRow: 1,
          height: "100vh",
          maxHeight: "620px",
        }}
        columns={[1, 2, "280px 620px 540px"]}
      >
        <Box>sdf</Box>
        <Box
          sx={{
            bg: "black",
            heigh: "200px",
            zIndex: 10,
            position: "relative",
            height: "100%",
          }}
        >
          fsdafasd
        </Box>
        <Box>dfas</Box>
      </Grid>

      <Box
        as="div"
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: -2,
        }}
      >
        {image && <BackgroundImage image={image} sx={{ zIndex: -1 }} />}
      </Box>
    </Container>
  )
}

export default LogoText
