import React from "react"
import { graphql } from "gatsby"
import { Box } from "theme-ui"

// import app components
import Layout from "../../components/Layout"
import singletile from "../../components/singleTile/SingleTile"
import doubletile from "../../components/doubleTile/DoubleTile"
import hero from "../../components/hero/Hero"
import introduction from "../../components/introduction/Introduction"
import cards from "../../components/cards/Cards"
import textform from "../../components/textForm/TextForm"
import testimonialslider from "../../components/testimonialSlider/TestimonialSlider"
import benefits from "../../components/benefits/Benefits"
import logos from "../../components/logos/Logos"
import partners from "../../components/partners/Partners"
import cardcarousel from "../../components/cardCarousel/CardCarousel"
import accordion from "../../components/accordion/Accordion"
import textimagepage from "../../components/textImagePage/TextImagePage"
import logotext from "../../components/logoText/LogoText"
import contact from "../../components/contact/Contact"

const blocks = {
  singletile,
  doubletile,
  hero,
  introduction,
  cards,
  textform,
  testimonialslider,
  benefits,
  logos,
  partners,
  cardcarousel,
  accordion,
  textimagepage,
  logotext,
  contact,
}

const DefaultPageTemplate = (props) => {
  const {
    data: {
      wpPage: {
        seo,
        template: { acf },
      },
    },
  } = props

  return (
    <Layout {...props} seo={seo} isHomePage={props?.location?.pathname === "/"}>
      {acf?.content?.flex &&
        acf.content.flex.map(
          ({ id: fieldId, fieldGroupName, ...rest }, index) => {
            const id = fieldId || fieldGroupName?.split("_").pop().toLowerCase()

            const Component = blocks?.[id]
            return (
              Component && (
                <Box key={index}>
                  <Component {...rest} />
                </Box>
              )
            )
          }
        )}
    </Layout>
  )
}
export const CollectionQuery = graphql`
  query DefaultPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      template {
        ... on WpDefaultTemplate {
          templateName
          acf {
            content {
              flex {
                ... on WpDefaultTemplate_Acf_Content_Flex_Hero {
                  fieldGroupName
                  headlines {
                    fieldGroupName
                    headline
                  }
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Contact {
                  fieldGroupName
                  headline
                  link {
                    target
                    title
                    url
                  }
                  phonenumber
                  text
                  fax
                  emailaddress
                  address
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Introduction {
                  fieldGroupName
                  backgroundcolor
                  headline
                  text
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Singletile {
                  fieldGroupName
                  headline
                  link {
                    target
                    title
                    url
                  }
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Doubletile {
                  fieldGroupName
                  headline1
                  alignment
                  headline2
                  link1 {
                    target
                    title
                    url
                  }
                  tile1 {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }

                  link2 {
                    target
                    title
                    url
                  }
                  tile2 {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Cards {
                  fieldGroupName
                  columns
                  headline
                  subheading
                  cards {
                    fieldGroupName
                    headline
                    text
                    link {
                      target
                      title
                      url
                    }
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 550, placeholder: BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Testimonialslider {
                  fieldGroupName
                  backgroundcolor
                  headline
                  subheading
                  slide {
                    fieldGroupName
                    name
                    position
                    quote
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Benefits {
                  fieldGroupName
                  columns
                  headline
                  subheading
                  link {
                    target
                    title
                    url
                  }
                  cards {
                    fieldGroupName
                    headline
                    text
                    icon {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 200, placeholder: BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Logos {
                  fieldGroupName
                  columns
                  headline
                  subheading
                  cards {
                    fieldGroupName
                    text
                    logo {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 244, placeholder: BLURRED)
                        }
                      }
                    }
                    modal {
                      fieldGroupName
                      modaltext
                      modallink {
                        target
                        title
                        url
                      }
                      modalimage {
                        altText
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 244, placeholder: BLURRED)
                          }
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Textform {
                  fieldGroupName
                  alignment
                  formbackground
                  formid
                  headline
                  text
                  disclaimer
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Partners {
                  fieldGroupName
                  backgroundcolor
                  columns
                  headline
                  subheading
                  mainbutton {
                    target
                    title
                    url
                  }
                  cards {
                    fieldGroupName
                    link {
                      target
                      title
                      url
                    }
                    icon {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 350, placeholder: BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Cardcarousel {
                  fieldGroupName
                  headline
                  subheading
                  cards {
                    fieldGroupName
                    heading
                    text
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 350, placeholder: BLURRED)
                        }
                      }
                    }
                    defaultimage {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 350, placeholder: BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Textimagepage {
                  fieldGroupName
                  mainheadline
                  subheading
                  mainlink {
                    target
                    title
                    url
                  }
                  textimage {
                    fieldGroupName
                    alignment
                    headline
                    text
                    link {
                      target
                      title
                      url
                    }
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 250, placeholder: BLURRED)
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Locations {
                  fieldGroupName
                  location {
                    fieldGroupName
                    mainheading
                    address {
                      fieldGroupName
                      addressheading
                      addresslineone
                      addresslinetwo
                      phoneone
                      phonetwo
                      email
                    }
                  }
                }
              }
            }
            fieldGroupName
          }
        }
      }
    }
  }
`

export default DefaultPageTemplate
