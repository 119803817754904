import React from "react"
import { Box, Grid, Text, Container, Heading } from "theme-ui"
import Parser from "html-react-parser"
import { GatsbyImage } from "jam-cms"

// import app components
import BackgroundImage from "../BackgroundImage"
import Edges from "../Edges"
import Button from "../Button"
import Logo from "../../icons/pwww_corner.svg"

const SingleTile = (props) => {
  const { image, alignment, headline, logo, link } = props

  return (
    <Container id="single">
      <Grid
        sx={{
          postion: "relative",
          zIndex: 9,
          gridRow: 1,
          height: "auto",
          gap: 0,
          background: "black",
          pb: ["5px", ".4%"],
          pt: ["5px", null],
        }}
        columns={[1, "20% 43% 37%", "20% 43% 37%"]}
      >
        <Grid
          sx={{
            gridColumnStart: [1, 2, 2],
            gridRowStart: 1,
            gridRowEnd: 1,

            bg: "rgba(3, 15, 26, 0.7)",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            zIndex: 10,
            position: "relative",
            mt: [0, "60px"],
            height: "auto",
            width: "100%",
          }}
        >
          {image && (
            <Box
              sx={{
                gridRowStart: 1,
                gridColumnStart: 1,
                display: "relative",
                zIndex: "-1",
                width: "100%",
                "&:before": {
                  content: "''",
                  position: "absolute",
                  top: "0",
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(343.16deg, rgba(37, 55, 70, 0.85) 0%, rgba(37, 55, 70, 0.75) 14.85%, rgba(37, 55, 70, 0) 50.01%)",
                  zIndex: 10,
                },
                ".gatsby-image-wrapper": {
                  width: "100%",
                  height: ["320px", "100%"],
                },
              }}
            >
              {image?.svg ? (
                Parser(image.svg)
              ) : (
                <GatsbyImage
                  image={image}
                  alt={image?.altText}
                  objectPosition="0"
                  objectFit="cover"
                  className="gatsbyImage"
                />
              )}
            </Box>
          )}
          <Box
            mt={["auto", "auto"]}
            ml={["auto", "auto"]}
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              textAlign: "end",

              p: ["20px", "20px", "30px"],
            }}
          >
            {headline && (
              <Heading
                color="white"
                sx={{
                  letterSpacing: "1px",
                  fontWeight: 500,
                  fontSize: [8, 9, 14],
                }}
                children={headline}
              />
            )}
            {link?.url && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  mt: "20px",
                }}
              >
                <Button to={link.url}>{link.title}</Button>
              </Box>
            )}
          </Box>

          <Box
            mt={["auto", "auto"]}
            mr={["auto", "auto"]}
            color="white"
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              mb: "-5px",
              svg: {
                zIndex: 100,
                width: ["60%", "60%", "100%"],
                height: ["60%", "60%", "100%"],
              },
            }}
          >
            <Logo />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SingleTile
