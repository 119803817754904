import React from "react"
import { Box, Grid, Text, Container, Heading } from "theme-ui"
import Parser from "html-react-parser"
import { GatsbyImage } from "jam-cms"

// import app components
import BackgroundImage from "../BackgroundImage"
import Edges from "../Edges"
import Button from "../Button"
import Logo from "../../icons/pwww_corner.svg"

const DoubleTile = (props) => {
  const { tile1, alignment, headline1, link1, tile2, headline2, link2 } = props

  return (
    <Container>
      <Grid
        sx={{
          postion: "relative",
          zIndex: 9,
          gridRow: 1,
          height: "auto",
          gap: ["5px", ".4%"],
          background: "black",
          pb: alignment === "left" ? ["5px", ".4%"] : "80px",
        }}
        columns={alignment === "left" ? [1, "43% 43% 14%"] : [1, "13% 43% 43%"]}
      >
        <Grid
          sx={{
            gridColumnStart: alignment === "left" ? 1 : [1, 2],
            gridRowStart: 1,
            gridRowEnd: 1,

            zIndex: 10,
            position: "relative",
            height: "auto",
            width: "100%",
          }}
        >
          {tile1 && (
            <Box
              sx={{
                gridRowStart: 1,
                gridColumnStart: 1,
                display: "relative",
                zIndex: "-1",
                width: "100%",
                "&:before": {
                  content: "''",
                  position: "absolute",
                  top: "0",
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(343.16deg, rgba(37, 55, 70, 0.85) 0%, rgba(37, 55, 70, 0.75) 14.85%, rgba(37, 55, 70, 0) 50.01%)",
                  zIndex: 10,
                },
                ".gatsby-image-wrapper": {
                  width: "100%",
                  height: ["320px", "100%"],
                },
              }}
            >
              {tile1?.svg ? (
                Parser(tile1.svg)
              ) : (
                <GatsbyImage
                  image={tile1}
                  alt={tile1?.altText}
                  objectPosition="0"
                  objectFit="cover"
                  className="gatsbyImage"
                />
              )}
            </Box>
          )}
          <Box
            mt={["auto", "auto"]}
            ml={["auto", "auto"]}
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              textAlign: "end",

              p: ["20px", "20px", "30px"],
            }}
          >
            {headline1 && (
              <Heading
                color="white"
                sx={{
                  letterSpacing: "1px",
                  fontWeight: 500,
                  fontSize: [8, 9, 14],
                }}
                children={headline1}
              />
            )}
            {link1?.url && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  mt: "20px",
                }}
              >
                <Button to={link1.url}>{link1.title}</Button>
              </Box>
            )}
          </Box>

          <Box
            mt={["auto", "auto"]}
            mr={["auto", "auto"]}
            color="white"
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              mb: "-5px",
              svg: {
                width: ["60%", "60%", "100%"],
                height: ["60%", "60%", "100%"],
              },
            }}
          >
            <Logo />
          </Box>
        </Grid>

        <Grid
          sx={{
            gridColumnStart: alignment === "left" ? [1, 2] : [1, 3],
            gridRowStart: [2, 1],
            gridRowEnd: [3, 2],

            bg: "rgba(3, 15, 26, 0.7)",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            zIndex: 10,
            position: "relative",
            height: "auto",
            width: "100%",
          }}
        >
          {tile2 && (
            <Box
              sx={{
                gridRowStart: 1,
                gridColumnStart: 1,
                display: "relative",
                zIndex: "-1",
                width: "100%",
                "&:before": {
                  content: "''",
                  position: "absolute",
                  top: "0",
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(343.16deg, rgba(37, 55, 70, 0.85) 0%, rgba(37, 55, 70, 0.75) 14.85%, rgba(37, 55, 70, 0) 50.01%)",
                  zIndex: 10,
                },
                ".gatsby-image-wrapper": {
                  width: "100%",
                  height: ["320px", "100%"],
                },
              }}
            >
              {tile2?.svg ? (
                Parser(tile1.svg)
              ) : (
                <GatsbyImage
                  image={tile2}
                  alt={tile2?.altText}
                  objectPosition="0"
                  objectFit="cover"
                  className="gatsbyImage"
                />
              )}
            </Box>
          )}
          <Box
            mt={["auto", "auto"]}
            ml={["auto", "auto"]}
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              textAlign: "end",

              p: ["20px", "20px", "30px"],
            }}
          >
            {headline2 && (
              <Heading
                color="white"
                sx={{
                  letterSpacing: "1px",
                  fontWeight: 500,
                  fontSize: [8, 9, 14],
                }}
                children={headline2}
              />
            )}
            {link2?.url && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  mt: "20px",
                }}
              >
                <Button to={link2.url}>{link2.title}</Button>
              </Box>
            )}
          </Box>

          <Box
            mt={["auto", "auto"]}
            mr={["auto", "auto"]}
            color="white"
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              mb: "-5px",
              svg: {
                width: ["60%", "60%", "100%"],
                height: ["60%", "60%", "100%"],
              },
            }}
          >
            <Logo />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default DoubleTile
